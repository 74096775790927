import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
//
import { getAllRentables } from './rentable';
import { RemoveNullValues } from '../../utils/removeNullableValues';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    pricingGroups: [],
    pricingGroup: null,
    meta: {
        currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0
    },
    availablePricingGroup: [],
    onePricingGroupForReservation: {},
};
// ----------------------------------------------------------------------
export const getPricingGroups = createAsyncThunk('pricingGroups/getPricingGroups', async ({ page = '', rowsPerPage = '', orderBy = 'createdAt', order = 'desc', filterName = '', isPaging = false, }) => {
    const queryParams = {
        search: {
            name: filterName,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    const url = createDynamicURL('/pricingGroups', queryParams);
    try {
        const response = await axios.get(url);
        const data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'Somthing went wrong');
    }
});
export const getPricingGroup = createAsyncThunk('pricingGroups/getPricingGroup', async (id) => {
    let data;
    try {
        const response = await axios.get(`/pricingGroups/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createPricingGroup = createAsyncThunk('pricingGroups/createPricingGroup', async (Data) => {
    Data.rentableTypeId = Number(Data.rentableTypeId);
    let data;
    try {
        const response = await axios.post(`/pricingGroups`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const editPricingGroup = createAsyncThunk('pricingGroups/editPricingGroup', async ({ id, Data }) => {
    Data.rentableTypeId = Number(Data.rentableTypeId);
    let data;
    try {
        const response = await axios.put(`/pricingGroups/${id}`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deletePricingGroup = createAsyncThunk('pricingGroups/deletePricingGroup', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/pricingGroups/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getPricingGroups({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const deleteManyPricingGroups = createAsyncThunk('pricingGroups/deleteManyPricingGroups', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/pricingGroups/many`, { data: [...ids] });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getPricingGroups({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const addRentablesToPricingGroup = createAsyncThunk('pricingGroups/addRentablesToPricingGroup', async ({ ids, pricingGroupId, getParams, }, thunkAPI) => {
    let data;
    try {
        const response = await axios.put(`/pricingGroups/${pricingGroupId}/rentables`, { ids });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getAllRentables({ ...getParams }));
            thunkAPI.dispatch(getPricingGroup(pricingGroupId));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const removeRentablesFromPricingGroup = createAsyncThunk('pricingGroups/removeRentablesFromPricingGroup', async ({ ids, pricingGroupId, getParams, }, thunkAPI) => {
    let data;
    const newIds = ids.map((el) => Number(el));
    try {
        const response = await axios.delete(`/pricingGroups/${pricingGroupId}/rentables`, {
            data: { ids: newIds },
        });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getPricingGroup(pricingGroupId));
            thunkAPI.dispatch(getAllRentables({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const postPricingGroupForReservaton = createAsyncThunk('pricingGroups/postPricingGroupForReservaton', async ({ startDate, endDate, pricingGroupId, rentableId, }) => {
    const values = {
        startDate,
        endDate,
        pricingGroupId,
        rentableId,
    };
    if (!values.rentableId)
        delete values.pricingGroupId;
    try {
        const response = await axios.post(`/pricingGroups/forReservation`, RemoveNullValues(values));
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
const slice = createSlice({
    name: 'pricingGroup',
    initialState,
    reducers: {
        getOnePricingGroupForReservation(state, action) {
            state.onePricingGroupForReservation = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPricingGroups.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getPricingGroups.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.pricingGroups = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getPricingGroups.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getPricingGroup.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getPricingGroup.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.pricingGroup = action.payload;
        })
            .addCase(getPricingGroup.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(createPricingGroup.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createPricingGroup.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.pricingGroup = action.payload;
        })
            .addCase(createPricingGroup.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(editPricingGroup.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(editPricingGroup.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.pricingGroup = action.payload;
        })
            .addCase(editPricingGroup.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deletePricingGroup.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deletePricingGroup.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deletePricingGroup.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(deleteManyPricingGroups.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(deleteManyPricingGroups.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(deleteManyPricingGroups.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(postPricingGroupForReservaton.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(postPricingGroupForReservaton.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.availablePricingGroup = action.payload;
        })
            .addCase(postPricingGroupForReservaton.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(addRentablesToPricingGroup.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(addRentablesToPricingGroup.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(addRentablesToPricingGroup.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(removeRentablesFromPricingGroup.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(removeRentablesFromPricingGroup.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(removeRentablesFromPricingGroup.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const { getOnePricingGroupForReservation } = slice.actions;
export const reducer = slice.reducer;
export default slice;
